@tailwind base;
@tailwind components;
@tailwind utilities;
@import url(https://fonts.google.com/specimen/Manrope);

* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
  font-family: 'Manrope', sans-serif;
  font-size: 28px;

}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.attribution { font-size: 11px; text-align: center; }
    .attribution a { color: hsl(228, 45%, 44%); }
    
.d {
  lightCyan: hsl(193, 38%, 86%);
  neonGreen: hsl(150, 100%, 66%);
  grayishBlue: hsl(217, 19%, 38%);
  darkGrayishBlue: hsl(217, 19%, 24%);
  darkBlue: hsl(218, 23%, 16%)
}